import React from 'react';
import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';

const Backtotop = () => {
    const [back, setback] = useState(false)

    useEffect(() => {
        window.addEventListener("scroll", () => {
            if (window.scrollY > 100) {
                setback(true);
            } else {
                setback(false);
            }
        });
    })
    const scrollUp = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
    }
    return (
        <>
            {back && (
                <button style={{
                    position: "fixed",
                    bottom: "100px",
                    right: "40px",
                    height: "50px",
                    width: "50px",
                    fontSize: "25px",
                    borderRadius: "50px",
                    backgroundColor: "#FFF",
                }}
                    onClick={scrollUp}
                ><FontAwesomeIcon icon={faArrowUp} className='c-blue' /></button>
            )}
        </>
    )
}

export default Backtotop