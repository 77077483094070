import React from 'react';
import '../App.css';
import { motion } from "framer-motion";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload} from '@fortawesome/free-solid-svg-icons';
import { FaLink } from "react-icons/fa";
import { Link } from 'react-router-dom';
const Download = () => {
  return (
    <>
      <section className="download-sec-1 py-5 sec-color mt-6 mb-5 over" id='bg'>
        <div className="container" data-aos="fade-up" data-aos-duration="1000" data-aos-easing="ease-out-cubic">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-6 col-sm-12 my-3">
              <div className="meta sect-color py-4 px-4 text-center" id='bgg'>
                <div className="img-down d-flex align-items-center justify-content-around">
                  <img src={`assets/img/Meta-Logo.webp`} style={{ "width": "20%" }} alt="" />
                  <h3 className='text-white t-shadow fw-bolder mb-0'>Meta</h3>
                  <Link to='/'>
                    <motion.p whileHover={{ scale: 1.1 }}
                      whileTap={{ scale: 0.9 }} className="btn button-blue text-white mb-0">Download <FontAwesomeIcon icon={faDownload} /></motion.p>
                  </Link>
                </div>
                <img src={`assets/img/Tut/1.png`} alt="meta" className='rounded-5 img-fluid' />
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 my-3">
              <div className="android sect-color py-4 px-4 text-center" id='bgg'>
                <div className="img-down d-flex align-items-center justify-content-around">
                  <img src={`assets/img/And-Logo.webp`} style={{ "width": "15%" }} alt="" />
                  <h3 className='text-white t-shadow fw-bolder mb-0'>Android</h3>
                  <Link to='/'>
                    <motion.p whileHover={{ scale: 1.1 }}
                      whileTap={{ scale: 0.9 }} className="btn button-blue text-white mb-0">Download <FontAwesomeIcon icon={faDownload} /></motion.p>
                  </Link>
                </div>
                <img src={`assets/img/Tut/2.png`} alt="meta" className='rounded-5 img-fluid' />
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 my-3">
              <div className="ios sect-color py-4 px-4 text-center" id='bgg'>
                <div className="img-down d-flex align-items-center justify-content-around">
                  <img src={`assets/img/iOS-Logo.webp`} style={{ "width": "18%" }} alt="" />
                  <h3 className='text-white t-shadow fw-bolder mb-0'>iOS</h3>
                  <Link to='/'>
                    <motion.p whileHover={{ scale: 1.1 }}
                      whileTap={{ scale: 0.9 }} className="btn button-blue text-white mb-0">Download <FontAwesomeIcon icon={faDownload} /></motion.p>
                  </Link>
                </div>
                <img src={`assets/img/Tut/3.png`} alt="meta" className='rounded-5 img-fluid' />
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 my-3">
              <div className="vision sect-color py-4 px-4 text-center" id='bgg'>
                <div className="img-down d-flex align-items-center justify-content-around">
                  <img src={`assets/img/OS-Logo.webp`} style={{ "width": "18%" }} alt="" />
                  <h3 className='text-white t-shadow fw-bolder mb-0'>VisionOS</h3>
                  <Link to='/'>
                    <motion.p whileHover={{ scale: 1.1 }}
                      whileTap={{ scale: 0.9 }} className="btn button-blue text-white mb-0">Coming Soon</motion.p>
                  </Link>
                </div>
                <img src={`assets/img/Tut/4.png`} alt="meta" className='rounded-5 img-fluid' />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='sec-3 my-5' id='contact'>
        <div className="container bord p-3 bg-center sec-color" id='bgg' data-aos="fade-up" data-aos-duration="1000" data-aos-easing="ease-out-cubic">
          <h4 className='jt text-white mb-4 text-center wt m-auto'>Where expanding on to another platform as we speak do reach out to us, if you would like to see <strong>interior Verse </strong> on your design platform sooner</h4>
          <div className="row align-items-center justify-content-around">
            <div className="col-md-5 my-3">
              <h5 className='text-white text-center mb-0'>Start a Conversation with Us</h5>
            </div>
            <div className="col-md-7 my-3">
              <div className="formGroup news">
                <input type="email" placeholder="Enter Your Email" id="email_subscribe" name="email" className="Email" />
                <motion.button whileTap={{ scale: 0.5 }} type="button" value="Subscribe" className="SubscribeBtn">Sign Up! <FaLink /> </motion.button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Download