import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const TestCard = () => {

    let data = [
        { img: '1.png', sliderTitle: 'Sameer Goyal', sliderSubtitle: 'Business Owner', desc: 'Showcasing our offerings in interiorVerse has revolutionized our sales. Customers can see how products look in real spaces, leading to higher conversion rates & happier customers.' },
        { img: '2.png', sliderTitle: 'Mansi Mishra', sliderSubtitle: 'Real-Estate Professional', desc: 'interiorVerse is the future of interior design! The VR walkthroughs are mind-blowing, and the possibilities are endless. I cannot wait to see what interioXr comes up with next.' },
        { img: '3.png', sliderTitle: 'Raghu R. Rathore', sliderSubtitle: 'Product Head', desc: 'Orchestrating the vision of interiorVerse into a cohesive reality was a daily puzzle I deeply cherished. Working alongside such talented individuals made it a true adventure' },
        { img: '4.png', sliderTitle: 'Aditya Dhanraj Gupta', sliderSubtitle: 'Web Developer', desc: 'Crafting an intuitive and immersive VR experience for interiorVerse is both challenging and deeply satisfying. Seeing users navigate their dream homes seamlessly is the ultimate reward.' },
        { img: '6.png', sliderTitle: 'Rivanshu Jain', sliderSubtitle: 'Lead Developer', desc: 'interioXr is more than just a company; it is a community of innovators committed to shaping the future of design. Witnessing our teams talent and dedication fills me with immense pride. ' },
        { img: '5.png', sliderTitle: 'Tripti Suri', sliderSubtitle: 'Marketing Associate', desc: 'Seeing interiorVerse go from concept to reality has been thrilling. Sharing its potential with the world and witnessing its impact has been incredibly rewarding' }
    ];

    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        initialSlide: 0,

        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    let Datalist = data.map((val, i) => {
        return (
            <Slider key={i}>
                <div>
                    <div className="card card-color ">
                        <div className="card-header d-flex align-items-center border-0 gap-3">
                            <img src={`assets/img/Testimonials/${val.img}`} alt="testimonial-1" className='w-25 rounded-circle' />
                            <div className="image-text">
                                <h5 className='text-white'>{val.sliderTitle}</h5>
                                <p className='text-white'>{val.sliderSubtitle}</p>
                            </div>
                        </div>
                        <div className="card-body border-0">
                            <p className='text-white m-0'>{val.desc}</p>
                        </div>
                    </div>
                </div>
            </Slider>
        )
    })
    return (
        <>
            <div>
                <Slider {...settings}>
                    {Datalist}
                </Slider>
            </div>
        </>
    )
}

export default TestCard;