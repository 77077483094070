import React from 'react';
import '../App.css';
import { motion } from "framer-motion";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faCirclePlay, faLink } from '@fortawesome/free-solid-svg-icons';
import { FaLink } from "react-icons/fa";
import { Link } from 'react-router-dom';

const Why = () => {
    return (
        <>
            <section className="why-sec-1 sec-color py-4 mt-6 mb-5 over" id='bg'>
                <div className="container" data-aos="fade-up" data-aos-duration="1000" data-aos-easing="ease-out-cubic">
                    <div className="row align-items-center ">
                        <div className="col-md-2">
                            <img src={`assets/img/iXr-1.webp`} alt="ixr-2" className='I1 hidden' />
                        </div>
                        <div className="col-md-10 text-end">
                            <h1 className="text-white fs-80 fw-bolder wt mb-4 t-shadow ms-auto" id='text' style={{ "zIndex": "2", 'position': "relative" }}>A True 21st Century Millenial-run Boomer-backed & Gen-Z Inspired Design Company.</h1>
                        </div>
                    </div>
                    <div className="col-md-10">
                        <div className="row align-items-center mT" style={{ "marginTop": "-70px" }}>
                            <div className="col-md-10">
                                <Link to='/contact'>
                                    <motion.span whileHover={{ scale: 1.1 }}
                                        whileTap={{ scale: 0.9 }} className="btn button-blue text-white mb-3">Contact Us <FontAwesomeIcon icon={faArrowRight} /></motion.span>
                                </Link>
                                <p className='text-white wt' style={{ "zIndex": "2", 'position': "relative" }} id='text'>Experience fully immersive VR walkthroughs of your dream interiors before they become a reality. Our cutting-edge VR app takes you on a hyper-realistic journey through your designs, enabling you to visualize every detail in 3D. </p>
                            </div>
                            <div className="col-md-2">
                                <img src={`assets/img/iXr.webp`} alt="ixr" className='I1' style={{ "marginTop": "-292px", "marginLeft": "187px", "zIndex": "1", 'position': "relative" }} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="why-sec-2 sec-color py-4 over" id='bg'>
                <div className="container text-center" data-aos="fade-up" data-aos-duration="1000" data-aos-easing="ease-out-cubic">
                    <h1 className="text-white fw-bolder fs-70 t-shadow mb-2 index" id='text'>See How interiorVerse Aides Industry.</h1>
                    <div class="accordion accordion-flush" id="accordionFlushExample">
                        <div class="accordion-item bg-transparent">
                            <h2 class="accordion-header d-flex align-items-center my-4">
                                <img src={`assets/img/covers/1.webp`} alt="B2B" className='img-fluid rounded-5' />
                                <button class="accordion-button collapsed bg-transparent text-white fs-1" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                    #interiorVerse For Arch & IntDes
                                </button>
                            </h2>
                            <div id="flush-collapseOne" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                <div className="row align-items-center">
                                    <div className="col-md-6 col-sm-12 my-3">
                                        <img src={`assets/img/Arch & IntDes/1.webp`} alt="Arch & IntDes-1" className='img-fluid rounded-5' />
                                    </div>
                                    <div className="col-md-6 col-sm-12 my-3">
                                        <img src={`assets/img/Arch & IntDes/2.webp`} alt="Arch & IntDes-2" className='img-fluid rounded-5' />
                                    </div>
                                    <div className="col-12 my-3">
                                        <div className="formGroup news m-auto">
                                            <input type="email" placeholder="Enter Your Email" id="email_subscribe" name="email" className="Email" />
                                            <motion.button whileTap={{ scale: 0.5 }} type="button" value="Subscribe" className="SubscribeBtn">Register your Interest! <FaLink /> </motion.button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item bg-transparent">
                            <h2 class="accordion-header d-flex align-items-center my-4">
                                <img src={`assets/img/covers/2.webp`} alt="B2B" className='img-fluid rounded-5' />
                                <button class="accordion-button collapsed bg-transparent text-white fs-1" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                    #interiorVerse For Event Planners
                                </button>
                            </h2>
                            <div id="flush-collapseTwo" class="accordion-collapse collapse py-4" data-bs-parent="#accordionFlushExample">
                                <div className="row align-items-center">
                                    <div className="col-md-6 col-sm-12 my-3">
                                        <img src={`assets/img/Event/1.webp`} alt="event-1" className='img-fluid rounded-5' />
                                    </div>
                                    <div className="col-md-6 col-sm-12 my-3">
                                        <img src={`assets/img/Event/2.webp`} alt="event-2" className='img-fluid rounded-5' />
                                    </div>
                                    <div className="col-12 my-3">
                                        <div className="formGroup news m-auto">
                                            <input type="email" placeholder="Enter Your Email" id="email_subscribe" name="email" className="Email" />
                                            <motion.button whileTap={{ scale: 0.5 }} type="button" value="Subscribe" className="SubscribeBtn">Register your Interest! <FaLink /> </motion.button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item bg-transparent">
                            <h2 class="accordion-header d-flex align-items-center my-4">
                                <img src={`assets/img/covers/3.webp`} alt="B2B" className='img-fluid rounded-5' />
                                <button class="accordion-button collapsed bg-transparent text-white fs-1" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                                    #interiorVerse For Hotels
                                </button>
                            </h2>
                            <div id="flush-collapseThree" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                <div className="row align-items-center">
                                    <div className="col-md-6 col-sm-12 my-3">
                                        <img src={`assets/img/Hotels/1.webp`} alt="Hotels-1" className='img-fluid rounded-5' />
                                    </div>
                                    <div className="col-md-6 col-sm-12 my-3">
                                        <img src={`assets/img/Hotels/2.webp`} alt="Hotels-2" className='img-fluid rounded-5' />
                                    </div>
                                    <div className="col-12 my-3">
                                        <div className="formGroup news m-auto">
                                            <input type="email" placeholder="Enter Your Email" id="email_subscribe" name="email" className="Email" />
                                            <motion.button whileTap={{ scale: 0.5 }} type="button" value="Subscribe" className="SubscribeBtn">Register your Interest! <FaLink /> </motion.button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item bg-transparent">
                            <h2 class="accordion-header d-flex align-items-center my-4">
                                <img src={`assets/img/covers/4.webp`} alt="B2B" className='img-fluid rounded-5' />
                                <button class="accordion-button collapsed bg-transparent text-white fs-1" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                                    #interiorVerse For Theme Parks
                                </button>
                            </h2>
                            <div id="flush-collapseFour" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                <div className="row align-items-center">
                                    <div className="col-md-6 col-sm-12 my-3">
                                        <img src={`assets/img/Theme/1.webp`} alt="Theme-1" className='img-fluid rounded-5' />
                                    </div>
                                    <div className="col-md-6 col-sm-12 my-3">
                                        <img src={`assets/img/Theme/2.webp`} alt="Theme-2" className='img-fluid rounded-5' />
                                    </div>
                                    <div className="col-12 my-3">
                                        <div className="formGroup news m-auto">
                                            <input type="email" placeholder="Enter Your Email" id="email_subscribe" name="email" className="Email" />
                                            <motion.button whileTap={{ scale: 0.5 }} type="button" value="Subscribe" className="SubscribeBtn">Register your Interest! <FaLink /> </motion.button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item bg-transparent">
                            <h2 class="accordion-header d-flex align-items-center my-4">
                                <img src={`assets/img/covers/5.webp`} alt="B2B" className='img-fluid rounded-5' />
                                <button class="accordion-button collapsed bg-transparent text-white fs-1" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive">
                                    #interiorVerse For Retail Showrooms
                                </button>
                            </h2>
                            <div id="flush-collapseFive" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                <div className="row align-items-center">
                                    <div className="col-md-6 col-sm-12 my-3">
                                        <img src={`assets/img/Retail/1.webp`} alt="Retail-1" className='img-fluid rounded-5' />
                                    </div>
                                    <div className="col-md-6 col-sm-12 my-3">
                                        <img src={`assets/img/Retail/2.webp`} alt="Retail-2" className='img-fluid rounded-5' />
                                    </div>
                                    <div className="col-12 my-3">
                                        <div className="formGroup news m-auto">
                                            <input type="email" placeholder="Enter Your Email" id="email_subscribe" name="email" className="Email" />
                                            <motion.button whileTap={{ scale: 0.5 }} type="button" value="Subscribe" className="SubscribeBtn">Register your Interest! <FaLink /> </motion.button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item bg-transparent">
                            <h2 class="accordion-header d-flex align-items-center my-4">
                                <img src={`assets/img/covers/6.webp`} alt="B2B" className='img-fluid rounded-5' />
                                <button class="accordion-button collapsed bg-transparent text-white fs-1" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSix" aria-expanded="false" aria-controls="flush-collapseSix">
                                    #interiorVerse For Real Estate Development
                                </button>
                            </h2>
                            <div id="flush-collapseSix" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                <div className="row align-items-center">
                                    <div className="col-md-6 col-sm-12 my-3">
                                        <img src={`assets/img/Real/1.webp`} alt="Real-1" className='img-fluid rounded-5' />
                                    </div>
                                    <div className="col-md-6 col-sm-12 my-3">
                                        <img src={`assets/img/Real/2.webp`} alt="Real-2" className='img-fluid rounded-5' />
                                    </div>
                                    <div className="col-12 my-3">
                                        <div className="formGroup news m-auto">
                                            <input type="email" placeholder="Enter Your Email" id="email_subscribe" name="email" className="Email" />
                                            <motion.button whileTap={{ scale: 0.5 }} type="button" value="Subscribe" className="SubscribeBtn">Register your Interest! <FaLink /> </motion.button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item bg-transparent">
                            <h2 class="accordion-header d-flex align-items-center my-4">
                                <img src={`assets/img/covers/7.webp`} alt="B2B" className='img-fluid rounded-5' />
                                <button class="accordion-button collapsed bg-transparent text-white fs-1" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSeven" aria-expanded="false" aria-controls="flush-collapseSeven">
                                    #interiorVerse For Exhibition Organizers
                                </button>
                            </h2>
                            <div id="flush-collapseSeven" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                <div className="row align-items-center">
                                    <div className="col-md-6 col-sm-12 my-3">
                                        <img src={`assets/img/Exhibition/1.webp`} alt="Exhibition-1" className='img-fluid rounded-5' />
                                    </div>
                                    <div className="col-md-6 col-sm-12 my-3">
                                        <img src={`assets/img/Exhibition/2.webp`} alt="Exhibition-2" className='img-fluid rounded-5' />
                                    </div>
                                    <div className="col-12 my-3">
                                        <div className="formGroup news m-auto">
                                            <input type="email" placeholder="Enter Your Email" id="email_subscribe" name="email" className="Email" />
                                            <motion.button whileTap={{ scale: 0.5 }} type="button" value="Subscribe" className="SubscribeBtn">Register your Interest! <FaLink /> </motion.button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item bg-transparent">
                            <h2 class="accordion-header d-flex align-items-center my-4">
                                <img src={`assets/img/covers/8.webp`} alt="B2B" className='img-fluid rounded-5' />
                                <button class="accordion-button collapsed bg-transparent text-white fs-1" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseEight" aria-expanded="false" aria-controls="flush-collapseEight">
                                    #interiorVerse For Wedding Organizers
                                </button>
                            </h2>
                            <div id="flush-collapseEight" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                <div className="row align-items-center">
                                    <div className="col-md-6 col-sm-12 my-3">
                                        <img src={`assets/img/Exhibition/1.webp`} alt="Exhibition-1" className='img-fluid rounded-5' />
                                    </div>
                                    <div className="col-md-6 col-sm-12 my-3">
                                        <img src={`assets/img/Exhibition/2.webp`} alt="Exhibition-2" className='img-fluid rounded-5' />
                                    </div>
                                    <div className="col-12 my-3">
                                        <div className="formGroup news m-auto">
                                            <input type="email" placeholder="Enter Your Email" id="email_subscribe" name="email" className="Email" />
                                            <motion.button whileTap={{ scale: 0.5 }} type="button" value="Subscribe" className="SubscribeBtn">Register your Interest! <FaLink /> </motion.button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item bg-transparent">
                            <h2 class="accordion-header d-flex align-items-center my-4">
                                <img src={`assets/img/covers/9.webp`} alt="B2B" className='img-fluid rounded-5' />
                                <button class="accordion-button collapsed bg-transparent text-white fs-1" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseNine" aria-expanded="false" aria-controls="flush-collapseNine">
                                    #interiorVerse For Office Spaces
                                </button>
                            </h2>
                            <div id="flush-collapseNine" class="accordion-collapse collapse py-4" data-bs-parent="#accordionFlushExample">
                                <div className="row align-items-center">
                                    <div className="col-md-6 col-sm-12 my-3">
                                        <img src={`assets/img/office/1.webp`} alt="office-1" className='img-fluid rounded-5' />
                                    </div>
                                    <div className="col-md-6 col-sm-12 my-3">
                                        <img src={`assets/img/office/2.webp`} alt="office-2" className='img-fluid rounded-5' />
                                    </div>
                                    <div className="col-12 my-3">
                                        <div className="formGroup news m-auto">
                                            <input type="email" placeholder="Enter Your Email" id="email_subscribe" name="email" className="Email" />
                                            <motion.button whileTap={{ scale: 0.5 }} type="button" value="Subscribe" className="SubscribeBtn">Register your Interest! <FaLink /> </motion.button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item bg-transparent">
                            <h2 class="accordion-header d-flex align-items-center my-4">
                                <img src={`assets/img/covers/10.webp`} alt="B2B" className='img-fluid rounded-5' />
                                <button class="accordion-button collapsed bg-transparent text-white fs-1" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTen" aria-expanded="false" aria-controls="flush-collapseTen">
                                    #interiorVerse For Office Products
                                </button>
                            </h2>
                            <div id="flush-collapseTen" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                <div className="row align-items-center">
                                    <div className="col-md-6 col-sm-12 my-3">
                                        <img src={`assets/img/Products/1.webp`} alt="Products-1" className='img-fluid rounded-5' />
                                    </div>
                                    <div className="col-md-6 col-sm-12 my-3">
                                        <img src={`assets/img/Products/2.webp`} alt="Products-2" className='img-fluid rounded-5' />
                                    </div>
                                    <div className="col-12 my-3">
                                        <div className="formGroup news m-auto">
                                            <input type="email" placeholder="Enter Your Email" id="email_subscribe" name="email" className="Email" />
                                            <motion.button whileTap={{ scale: 0.5 }} type="button" value="Subscribe" className="SubscribeBtn">Register your Interest! <FaLink /> </motion.button>
                                        </div>
                                    </div>  
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='home-sec-2 sec-color py-5 my-5 over' id='bgg'>
                <div className="container" data-aos="fade-up" data-aos-duration="1000" data-aos-easing="ease-out-cubic">
                    <h1 className="text-white fs-85 fw-bolder w-75 mb-4 t-shadow ms-auto text-end" >Subscribe To Our Updates</h1>
                    <div className="row align-items-center mb-3" style={{ "marginTop": "-55px" }}>
                        <div className="col-md-7" >
                            <img src={`assets/img/in-between.gif`} alt="interior-2" className='w-100' />
                        </div>
                        <div className="col-md-5" >
                            <h3 className='text-white pb-2'>Stay in the loop with latest developments as we welcome the future, together.</h3>
                            <Link to='/' className="text-white">
                                <p><FontAwesomeIcon icon={faCirclePlay} /> View Post Newsletters</p>
                            </Link>
                            <div className="formGroup news">
                                <input type="email" placeholder="Enter Your Email" id="email_subscribe" name="email" className="Email" />
                                <motion.button whileTap={{ scale: 0.5 }} type="button" value="Subscribe" className="SubscribeBtn">Sign Up! <FontAwesomeIcon icon={faLink} /></motion.button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Why